.react-autocomplete-input {
   background-clip: padding-box;
   background-color: #fff;
   border: 1px solid rgba(0,0,0,0.15);
   bottom: auto;
   box-shadow: 0 6px 12px rgba(0,0,0,0.175);
   display: block;
   font-size: 14px;
   list-style: none;
   padding: 1px;
   position: absolute;
   text-align: left;
   z-index: 20000;
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
}

.react-autocomplete-input > li.active {
  background-color: #337ab7;
  color: #fff;
}
